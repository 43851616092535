<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmGPathSessionFormData && Object.keys(vmGPathSessionFormData).length > 0">
              <div class="form-row">
              <!-- Session State Filter -->
              <b-col class="col-md-12 mb-3">
                <label for="validationsession_state_desc">
                  {{cvSessionStateLabel}}</label
                >
                <select v-model="vmGPathSessionFormData.session_state" class="form-control" title="session states"  @change="updateSessionData">
                  <option class="font-size-14" disabled selected>GPath Session States</option>
                  <option class="font-size-14" v-for="(sessionState, index) of GPathStateObj" :key="(index+1)" :value="index">
                    {{sessionState}}
                  </option>
                </select>
              </b-col><!-- Session State Filter -->
              <div class="col-md-12 mb-3">
                <label for="validationsession_state_desc">
                  {{cvSessionReportLabel}}</label
                >
                <input
                  v-model="vmGPathSessionFormData.report"
                  type="text"
                  class="form-control"
                  required>
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsession_state_desc">
                  {{cvSessionPayIdLabel}}</label
                >
                <input
                  v-model="vmGPathSessionFormData.pay_tid"
                  type="text"
                  class="form-control"
                  required>
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsession_state_desc">
                  {{cvSessionAffcodeLabel}}</label
                >
                <input
                  v-model="vmGPathSessionFormData.aff_code"
                  type="text"
                  class="form-control"
                  required>
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsession_state_desc">
                  {{cvSessionAffcodeAllocatorLabel}}</label
                >
                <input
                  v-model="vmGPathSessionFormData.aff_code_allocator"
                  type="text"
                  class="form-control"
                  required>
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsession_state_desc">
                  {{cvsSessionCounsellorNotesLabel}}</label
                >
                <textarea
                  v-model="vmGPathSessionFormData.counsellor_notes"
                  type="text"
                  class="form-control textarea"
                  required>
                </textarea>
              </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="gpath_sessionEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GPathSessions } from "../../../FackApi/api/GPathSession"
import ApiResponse from "../../../Utils/apiResponse"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "GPathSessionEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGPathSessionObj: {
      type: Object,
      default: function () {
        return {
          "session_state": "",
          "session_state_desc": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "gpath_session_edit",
      cvCardTitle: "Edit GPaths Session",
      cvCardSubHeader: "Edit GPaths Session ",
      cvSubmitBtn: "Edit",
      cvSessionStateLabel: "session state",
      cvSessionReportLabel: "Session Report Link",
      cvSessionPayIdLabel: "session payId",
      cvSessionAffcodeLabel: "Aff code",
      cvSessionAffcodeAllocatorLabel: "Aff code Allocator",
      cvSessionStateDescLabel: "session state desc",
      cvsSessionCounsellorNotesLabel: "Session Counsellor Notes",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathSession Edit Response",
      vmGPathSessionFormData: {},
      GPathStateObj: {
        "1": "INITIATED",
        "2": "PERSONAL INFO COMPLETE",
        "3": "EDUCATION INFO COMPLETED",
        "4": "PAYMENT DONE",
        "5": "CAREER COMPLETED",
        "6": "SKILL COMPLETED",
        "7": "APTITUDE COMPLETED",
        "8": "EXTRACURRICULAR COMPLETED",
        "9": "ASSESSMENT COMPLETE [REPORT AWAITED]",
        "99": "REPORT GENERATED"
      } // If changed here ensure changes are done in school dashboard gpaths as well
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.gpath_sessionView()
  },
  methods: {
    /**
     * updateSessionData
      */
    updateSessionData (event) {
      const index = event.target.value
      this.vmGPathSessionFormData.session_state = index
      this.vmGPathSessionFormData.session_desc = this.GPathStateObj[index]
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathSessionFormData) {
          if (!this.vmGPathSessionFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * gpath_sessionView
     */
    async gpath_sessionView () {
      try {
        let gpathSessionId = this.propGPathSessionObj.gps_id
        let gpathSessionViewResp = await GPathSessions.gpath_sessionView(this, gpathSessionId, null)

        if (gpathSessionViewResp && gpathSessionViewResp.resp_status) {
          this.vmGPathSessionFormData = gpathSessionViewResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_sessionView() and Exception:", err.message)
      }
    },
    /**
     * gpath_sessionEdit
     */
    async gpath_sessionEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gpathSessionAddResp = await GPathSessions.gpath_sessionEdit(this, this.vmGPathSessionFormData)
        await ApiResponse.responseMessageDisplay(this, gpathSessionAddResp)

        if (gpathSessionAddResp && !gpathSessionAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGPathSessionEditModal", this.vmGPathSessionFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_sessionEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
